import React, { Component } from 'react';
import SubgroupsList from './subgroupsList';

class BrowseSubgroups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Subgrupos',
        }
    }

    componentDidMount() {
        this.props.setTitle(this.props.location.state.name);
    }

    render() {
        return (
            <SubgroupsList {...this.props} idgroup={this.props.match.params.id} />
        )
    }
}

export default BrowseSubgroups;