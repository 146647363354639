import axios from 'axios';

export const REQUEST_ITEMS = 'REQUEST_ITEMS';
export const requestItems = (data) => {
    return {
        type: REQUEST_ITEMS,
        payload: {
            data
        }
    }
}

export const REICIVE_ITEMS = 'REICIVE_ITEMS';
export const reiciveItems = (data, idSubgroup) => {
    return {
        type: REICIVE_ITEMS,
        payload: data,
        idSubgroup,
        reicivedAt: Date.now()
    }
}

export const getItems = (idSubgroup) => {
    return async (dispatch) => {
        dispatch(requestItems());
        const url = `subgroup/${idSubgroup}/items`;
        try {
            const result = await axios.get(url);
            const data = result.data;
            return dispatch(reiciveItems(data, idSubgroup));
        }
        catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
        
    }
}

function shouldFetchItems(state, idSubgroup) {
    if(idSubgroup === undefined) {
        return false;
    }
    const items = state.itemsReducer.data[idSubgroup];
    if(Array.isArray(items)) {
        return false;
    } else {
        return true;
    }
}

export const getItemsIfNeeded = (idSubgroup) => {
    return(dispatch, getState) => {
        if(
            shouldFetchItems( getState(), idSubgroup )
        ) {
            return(
                dispatch( getItems(idSubgroup) )
            );
        } else {
            return Promise.resolve();
        }
    }
}