import axios from 'axios';

export const INDEX_PAGE = 'INDEX_PAGE';
export const indexPage = (page) => {
    return {
        type: INDEX_PAGE,
        payload: page
    }
}

export const REQUEST_SUBGROUPS = 'REQUEST_SUBGROUPS';
export const requestSubgroups = (data) => {
    return {
        type: REQUEST_SUBGROUPS,
        payload: {
            data
        }
    }
}

export const REICIVE_SUBGROUPS = 'REICIVE_SUBGROUPS';
export const reiciveSubgroups = (data, idGroup) => {
    return {
        type: REICIVE_SUBGROUPS,
        payload: data,
        idGroup,
        reicivedAt: Date.now()
    }
}

export const getSubgroups = (idGroup) => {
    return async (dispatch) => {
        dispatch(requestSubgroups());
        const url = `group/${idGroup}/subgroups`;
        try {
            const result = await axios.get(url);
            const data = result.data;
            return dispatch(reiciveSubgroups(data, idGroup));
        }
        catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
        
    }
}

function shouldFetchSubgroups(state, idGroup) {
    if(idGroup === undefined) {
        return false;
    }
    const subgroups = state.subgroupsReducer.data[idGroup];
    if(Array.isArray(subgroups)) {
        return false;
    } else {
        return true;
    }
}

export const getSubgroupsIfNeeded = (idGroup) => {
    return(dispatch, getState) => {
        if(
            shouldFetchSubgroups( getState(), idGroup )
        ) {
            return(
                dispatch( getSubgroups(idGroup) )
            );
        } else {
            return Promise.resolve();
        }
    }
}