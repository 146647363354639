import axios from 'axios';

export const REQUEST_ITEM_DETAILS = 'REQUEST_ITEM_DETAILS';
export const requestItemDetails = (data) => {
    return {
        type: REQUEST_ITEM_DETAILS,
    }
}

export const REICIVE_ITEM_DETAILS = 'REICIVE_ITEM_DETAILS';
export const reiciveItemDetails = (data, idItem) => {
    return {
        type: REICIVE_ITEM_DETAILS,
        payload: data,
        idItem,
        reicivedAt: Date.now()
    }
}

export const getItemDetails = (idItem) => {
    return async (dispatch) => {
        dispatch(requestItemDetails());
        const url = `item/${idItem}`;
        try {
            const result = await axios.get(url);
            const data = result.data;
            return dispatch(reiciveItemDetails(data, idItem));
        }
        catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
        
    }
}

function shouldFetchItemDetails(state, idItem) {
    if(idItem === undefined) {
        return false;
    }
    const itemDetails = state.itemDetailsReducer.data[idItem];
    if(typeof itemDetails === 'object' && itemDetails !== null) {
        return false;
    } else {
        return true;
    }
}

export const getItemDetailsIfNeeded = (idItem) => {
    return(dispatch, getState) => {
        if(
            shouldFetchItemDetails( getState(), idItem )
        ) {
            return(
                dispatch( getItemDetails(idItem) )
            );
        } else {
            return Promise.resolve();
        }
    }
}