import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List, ActivityIndicator, Badge, WhiteSpace, WingBlank } from 'antd-mobile';
import { getItemsIfNeeded } from '../../redux/actions/items';
import './browseItems.css';

const Item = List.Item;
const Brief = Item.Brief;
const imageUrlBasename = 'http://api.nslibreria.com.ar';
const imageFilepath = '/sys/img/';
const imageThumbPrefix = 'thumb_';
const thumbnailPath = imageUrlBasename + imageFilepath + imageThumbPrefix;

class BrowseItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idSubgroup: this.props.match.params.id,
            items: [],
        }
        this.goToItem =  this.goToItem.bind(this);
    }

    componentDidMount() {
        this.props.setTitle(this.props.location.state.name);
        const idSubgroup = this.state.idSubgroup;
        this.props.getItemsIfNeeded(idSubgroup).then( () => {
            if(!this.props.isFetching) {
                this.setState({
                    items: this.props.items.data[idSubgroup],
                    loading: false,
                });
            }
        }
    );
    window.scrollTo(0, 0);
    }

    goToItem({id_articulo}) {
        this.props.history.push(`/item/${id_articulo}`);
    }

    stockControl({stock_actual, stock_minimo}) {
        if(stock_actual <= stock_minimo && stock_minimo > 0) {
            return <Badge text={stock_actual}/>
        } else if(stock_minimo > 0) {
            return <Badge text={stock_actual} style={{ backgroundColor: '#21b68a' }}/>
        } else {
            return stock_actual;
        }
    }

    render() {
        const items = this.state.items.map(item => 
            <Item 
                arrow="horizontal" 
                onClick={() => this.goToItem(item)} 
                key={item.id_articulo}
                extra={item.precio_lista !== null ? `ARS $${item.precio_lista}` : 'ART. SIN PRECIO'}
                multipleLine
                wrap
                thumb={item.image_path ? thumbnailPath + item.image_path : '/img/no-photo.svg'}
            >
                {item.descripcion} <Brief>{item.codigo}</Brief>
            </Item>
        );
        return (
            <div>
                <ActivityIndicator 
                    toast
                    text="Cargando..."
                    animating={this.state.loading}
                />
                <List>
                    {items}
                </List>
                <WhiteSpace/>
                <WingBlank>
                    <small>Los precios no incluyen el IVA</small>
                </WingBlank>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        items: state.itemsReducer,
    }
};

const mapDispatchToProps = {
    getItemsIfNeeded,
};

export default connect(mapStateToProps,mapDispatchToProps)(BrowseItems);