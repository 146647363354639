import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List, ActivityIndicator } from 'antd-mobile';
import { getSubgroupsIfNeeded, indexPage } from '../../redux/actions/subgroups';

const Item = List.Item;

class SubgroupsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pages: [[], [], []],
            loading: true,
            items: [],
        }
    }

    changePage = (e) => {
        let page = e.nativeEvent.selectedSegmentIndex;
        this.setState({
            page,
        });
        this.props.indexPage(page);
    }

    goItems = (subgroup) => this.props.history.push(
        `/subgrupo/${subgroup.id_marca}`, {name: subgroup.marca}
    );

    componentDidMount() {
        const idGroup = this.props.idgroup;
        this.props.getSubgroupsIfNeeded(idGroup).then( () => {
                if(!this.props.isFetching) {
                    this.setState({
                        items: this.props.subgroups.data[idGroup],
                        loading: false,
                    });
                }
            }
        );
        window.scrollTo(0, 0);
    }

    render() {
        const items = this.state.items.map(subgroup => 
            <Item arrow="horizontal" onClick={() => this.goItems(subgroup)} key={subgroup.id_marca}>{subgroup.marca}</Item>
        );
        return (
            <div>
                <ActivityIndicator 
                    toast
                    text="Cargando..."
                    animating={this.state.loading}
                />
                <List>
                    {items}
                </List>
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        subgroups: state.subgroupsReducer,
    }
};

const mapDispatchToProps = {
    getSubgroupsIfNeeded,
    indexPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubgroupsList);