import React from 'react';
import { withRouter } from 'react-router-dom';
import { TabBar } from 'antd-mobile';

const Footer = (props) => 
  <div style={{position: 'fixed', bottom: 0, width: '100%', zIndex: 999}}>
  <TabBar
    tabBarPosition="bottom"
  >
    <TabBar.Item
        title="Inicio"
        key="home"
        icon={
            <div style={{
                width: '22px',
                height: '22px',
                background: 'url(/img/home.svg) center center /  21px 21px no-repeat' }}
            />
            }
        onPress={() => props.history.push('/')}
    />
    <TabBar.Item
        title="Perfil"
        key="profile"
        icon={
            <div style={{
                width: '22px',
                height: '22px',
                background: 'url(/img/profile.svg) center center /  21px 21px no-repeat' }}
            />
            }
        onPress={() => props.history.push('/profile')}
    />
  </TabBar>
  </div>

export default withRouter(Footer);