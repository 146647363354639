import { REQUEST_SUBGROUPS, REICIVE_SUBGROUPS, INDEX_PAGE } from '../actions/subgroups';

const defaultState = { data: {} };

export const subgroupsReducer = (state = defaultState, action) => {
    switch(action.type) {
        case REQUEST_SUBGROUPS:
            return { 
                state: {...state},
                isFetching: true 
            };
        case REICIVE_SUBGROUPS:
            return { 
                data: {...state.state.data, [action.idGroup]: action.payload},
                isFetching: false 
            };
        default:
            return state;
    }
}

export const indexReducer = (state = 0, action) => {
    switch(action.type) {
        case INDEX_PAGE:
            return {
                page: action.payload
            }
        default:
            return state;
    }
}