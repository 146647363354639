import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List, Card, WingBlank, WhiteSpace, ActivityIndicator, ImagePicker, ActionSheet } from 'antd-mobile';
import { getItemDetailsIfNeeded } from '../../redux/actions/itemDetails';
import './itemDetails.css';

const Header = Card.Header;
const Body = Card.Body;

// fix touch to scroll background page on iOS
// https://github.com/ant-design/ant-design-mobile/issues/307
// https://github.com/ant-design/ant-design-mobile/issues/163
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
let wrapProps;
if (isIPhone) {
  wrapProps = {
    onTouchStart: e => e.preventDefault(),
  };
}

// const imageUrlBasename = process.env.REACT_APP_ROUTER_BASENAME;
const imageUrlBasename = 'http://api.nslibreria.com.ar';
const imageFilepath = '/sys/img/';
const imagePath = imageUrlBasename + imageFilepath;

class ItemDetails extends Component {

    constructor(props) {
        super(props);

        const idItem = this.props.match.params.id;

        this.state = {
            idItem,
            image: [],
            loading: true,
        }

        this.onChangeImage = this.onChangeImage.bind(this);
    }

    componentDidMount() {
        const idItem = this.state.idItem;
        this.props.getItemDetailsIfNeeded(idItem).then( () => {
            if(!this.props.isFetching) {
                let itemDetails = this.props.itemDetails.data[this.state.idItem];
                let image = itemDetails.image_path !== null ? 
                                [{url: imagePath + itemDetails.image_path }] : 
                                [{url: '/img/no-photo.svg' }];
                this.setState({
                    itemDetails,
                    loading: false,
                    image
                });
            }
        });
        window.scrollTo(0,0);
    }

    onChangeImage(files, type, index) {
        this.setState({image: files});
        const showActionSheet = () => {
            const BUTTONS = ['Guardar', 'Descartar'];
            ActionSheet.showActionSheetWithOptions({
              options: BUTTONS,
              cancelButtonIndex: BUTTONS.length - 1,
              // title: 'title',
              message: 'Guardar nueva imagen?',
              maskClosable: true,
              'data-seed': 'logId',
              wrapProps,
            },
            (buttonIndex) => {
              if(buttonIndex === 1) {
                  this.setState({image: []});
              }
            });
        }
        if(files.length > 0 ) {
            showActionSheet();
            console.log(files);
        }
    }

    render() {
        let itemDetails;
        itemDetails = this.state.itemDetails ?
                <Card>
                    <Header 
                        title={this.state.itemDetails.descripcion}
                    />
                    <Body>
                        <ImagePicker
                            files={this.state.image}
                            multiple={false}
                            selectable={this.state.image.length < 1}
                            length={1}
                            onChange={this.onChangeImage}
                            onImageClick={(index, fs) => console.log(fs)}
                            disableDelete
                        />
                    </Body>
                    <List>
                        <List.Item extra={this.state.itemDetails.codigo}>Código</List.Item>
                        <List.Item extra={undefined !== this.state.itemDetails.precio_lista ? `ARS $${this.state.itemDetails.precio_lista}` : 'SIN PRECIO'}>Precio de lista</List.Item>
                        <List.Item extra={this.state.itemDetails.iva_tipo === 'G' ? `${this.state.itemDetails.iva_valor}%` : 'Exento'}>IVA</List.Item>
                        <List.Item extra={undefined !== this.state.itemDetails.precio_final ? `ARS $${this.state.itemDetails.precio_final}` : 'SIN PRECIO'}>Precio Final</List.Item>
                    </List>
                </Card>
            : <WhiteSpace/>;
        return (
            <div>
                <WhiteSpace />
                <WingBlank>
                <ActivityIndicator 
                    toast
                    text="Cargando..."
                    animating={this.state.loading}
                />
                {itemDetails}
            </WingBlank>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        itemDetails: state.itemDetailsReducer,
    }
};

const mapDispatchToProps = {
    getItemDetailsIfNeeded,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetails);