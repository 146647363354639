const defaultState = { data: {} };

export const userReducer = (state = defaultState, action) => {
    switch(action.type) {
        case 'USER_LOGGED_IN':
            return { 
                username: action.payload.username,
                avatarUrl: action.payload.avatarUrl,
            };
        default:
            return state;
    }
}