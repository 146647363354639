import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavBar, Icon, Popover } from 'antd-mobile';

function Header (props) {

  const [visible, setVisible] = useState(false);
  
  // ### BLOQUE HORRIBLE ###
  
  let userMenuLogin = <Popover.Item key="login" icon={<img src={`/img/login.svg`} className="am-icon am-icon-xs" alt="" />}>Ingresar</Popover.Item>
  
  if(props.user.username) {
    userMenuLogin = <Popover.Item key="logout" icon={<img src={`/img/exit.svg`} className="am-icon am-icon-xs" alt="" />}>Salir</Popover.Item>;
  }

  const avatarUrl = props.user.avatarUrl ? process.env.REACT_APP_BASE_URL + props.user.avatarUrl : '/img/user.svg';

  // ### FIN BLOQUE HORRIBLE ###

  function onSelect(opt) {
    setVisible(false);
    if(opt.key === "login") {
      props.history.push('/login');
    } else {
      localStorage.clear();
      props.history.push('/login');
    }
  }

  return (
    <NavBar
      mode="dark"
      icon={<Icon type="left" />}
      onLeftClick={() => props.history.goBack()}
      rightContent={[
        <Icon key="0" type="search" style={{ marginRight: '16px' }} />,
        <Popover 
          key="1"
          visible={visible}
          onSelect={onSelect}
          overlay={[
            userMenuLogin
          ]}
        >
        <div style={{
          width: '23px',
          height: '23px',
          borderRadius: '50%',
          background: `#FFF url(${avatarUrl}) no-repeat center`,
          backgroundSize: '21px'
          }}
          key="2"
          onClick={() => console.log('al profile')}
      /></Popover>
      ]}
    >{props.title}</NavBar>
  )
}
  const mapStateToProps = state => {
    return { user: state.userReducer };
  };

export default connect(mapStateToProps)(withRouter(Header));