import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.request.use( config => {
    let apiToken = localStorage.getItem('api_token');
    if(apiToken) {
        config.headers['api-token'] = apiToken;
    }
    return config;
});

axios.interceptors.response.use( response => {
    return response;
}, error => {
    let status = error.response.status;
    if(status === 401) {
        localStorage.clear();
        window.location.replace('/login');
    }
    return Promise.reject(error);
});