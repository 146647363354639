import React from 'react';
import { Result } from 'antd-mobile';

const myImg = src => <img src={src} className="spe am-icon am-icon-md" style={{width: '60px', height: '60px'}} alt="" />;

const Results = () => (
<div className="result-example">
  <Result
    img={myImg('img/results.svg')}
    title="Sin resultados"
    message="Intente otra búsqueda."
  />
  </div>
);

export default Results;