import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import BrowseGroups from './components/store/browseGroups';
import BrowseSubgroups from './components/store/browseSubgroups';
import BrowseItems from './components/store/browseItems';
import ItemDetails from './components/store/itemDetails';
import Header from './components/ui/header';
import Footer from './components/ui/footer';
import Dashboard from './components/ui/dashboard';
import Login from './components/common/login';
import Profile from './components/user/profile';
import './App.css';

const basename = process.env.REACT_APP_ROUTER_BASENAME;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedin : false,
      loading: true,
      title: 'NS LIBRERÍA',
    };
  }

  setTitle = (title) => this.setState({title: title});

  render() {
    return (
      <Router basename={basename}>
        <Header title={this.state.title} />
        <div style={{height: '100%', marginBottom: '60px'}}>
          <Switch>
            <Route exact path="/" render={props => <Dashboard {...props} setTitle={this.setTitle}/>} />
            <Route exact path='/login' component={Login} />
            <Route exact path="/profile" component={Profile} />
            <Route path='/grupos' render={props => <BrowseGroups {...props} setTitle={this.setTitle}/>} />
            <Route path='/grupo/:id' render={props => <BrowseSubgroups {...props} setTitle={this.setTitle}/>} />
            <Route path='/subgrupo/:id' render={props=> <BrowseItems {...props} setTitle={this.setTitle}/>} />
            <Route path='/item/:id' render={props=> <ItemDetails {...props} setTitle={this.setTitle}/>} />
          </Switch>
        </div>
        <Footer/>
      </Router>
    );
  }
}

export default App;