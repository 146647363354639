import { REQUEST_GROUPS, REICIVE_GROUPS, INDEX_PAGE } from '../actions/groups';

const defaultState = { data: [] };

export const groupsReducer = (state = defaultState, { type, payload }) => {
    switch(type) {
        case REQUEST_GROUPS:
            return { 
                state,
                isFetching: true 
            };
        case REICIVE_GROUPS:
            return { 
                data: payload,
                isFetching: false 
            };
        default:
            return state;
    }
}

export const indexReducer = (state = 0, action) => {
    switch(action.type) {
        case INDEX_PAGE:
            return {
                page: action.payload
            }
        default:
            return state;
    }
}