import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List, ActivityIndicator, SegmentedControl, WhiteSpace, WingBlank } from 'antd-mobile';
import { getGroupsIfNeeded, indexPage } from '../../redux/actions/groups';
import Results from '../common/results';

const Item = List.Item;

class GroupsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pages: [[], [], []],
            loading: true,
            groups: { data: [] },
            subgroups: [],
            items: [],
            resultEmpty: false,
        }
    }

    createPages() {
        if(this.props.groups.data.length === 0) {
            this.setState({resultEmpty: true});
            // throw new Error('Array of groups is empty!');
        } else {
            this.setState({resultEmpty: false});
        }
        const result = this.props.groups.data;
        const page1 = result.filter(value => value.categoria.match(/^[A-J]/));
        const page2 = result.filter(value => value.categoria.match(/^[K-S]/));
        const page3 = result.filter(value => value.categoria.match(/^[T-W]/));
        const page4 = result.filter(value => value.categoria.match(/^[0-9]/));
        this.setState({
            pages: [page1, page2, page3, page4],
            loading: false
        });
    }

    changePage = (e) => {
        let page = e.nativeEvent.selectedSegmentIndex;
        this.setState({
            page,
        });
        this.props.indexPage(page);
    }

    goSubgroups = (group) => this.props.history.push(
        `/grupo/${group.id_categoria}`, {name: group.categoria}
    );

    componentDidMount() {
        this.props.getGroupsIfNeeded().then( () => {
                if(!this.props.isFetching) {
                    this.createPages();
                }
            }
        );
        if(this.props.page) {
            this.setState({
                page: this.props.page
            });
        }
    }

    render() {
        if(this.state.resultEmpty) {
            return (
                <Results />
            )
        }
        const items = this.state.pages[this.state.page].map(group => 
            <Item arrow="horizontal" onClick={() => this.goSubgroups(group)} key={group.id_categoria}>{group.categoria}</Item>
        );
        return (
            <div>
                <ActivityIndicator 
                    toast
                    text="Cargando..."
                    animating={this.state.loading}
                />
                <WhiteSpace/>
                <WingBlank>
                    <SegmentedControl 
                        values={['A-J', 'K-S', 'T-W', '0-9']} 
                        selectedIndex={this.state.page} 
                        onChange={this.changePage}/>
                </WingBlank>
                <WhiteSpace/>
                <List>
                    {items}
                </List>
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        groups: state.groupsReducer,
        page: state.indexReducer.page,
    }
};

const mapDispatchToProps = {
    getGroupsIfNeeded,
    indexPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsList);