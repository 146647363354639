/*
* TODO: mejorar icono togglePassword
*
*
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { WingBlank, InputItem, Button, WhiteSpace } from 'antd-mobile';
import { userLoggedIn } from '../../redux/actions/user';
import { ReactComponent as ShowIcon } from './show.svg';
import { ReactComponent as HideIcon } from './hide.svg';

const baseUrl = process.env.REACT_APP_BASE_URL; 

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            loading: false,
            errorMsg: 'none',
            inputType: 'password',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleViewPassword = this.toggleViewPassword.bind(this);
    }

    toggleViewPassword() {
        let inputType = this.state.inputType === 'password' ? 'input' : 'password';
        this.setState({inputType});
    }

    handleSubmit(event) {
        event.preventDefault();
        if(this.state.username === '' || this.state.password === '') {
            this.setState({inputError: true});
            return;
        }
        this.setState({loading: true});
        const data = {
            name: this.state.username,
            password: this.state.password
        }
        axios.post('users/login', data).then(response => {
            localStorage.setItem('api_token', response.data.api_token);
            localStorage.setItem('username', this.state.username);
            if(response.data.avatar_url) {
                localStorage.setItem('avatar_url', baseUrl + response.data.avatar_url);
            }
            this.props.userLoggedIn(response.data);
            this.props.history.replace('/');
        }, error => {
            console.log(error);
            this.setState({loading: false, errorMsg: 'block'});
        });
    }

    render() {
        return (
            <WingBlank style={{ marginTop: '2em' }}>
                <div style={{ textAlign: 'center' }}>
                    <img src="/img/user.svg" style={{ width: '80px'}} alt="Logo" />
                </div>
                <WhiteSpace size="lg" />
                <InputItem 
                    placeholder="Usuario" 
                    name="username" 
                    value={this.state.username} 
                    onChange={e => this.setState({username: e})}
                    disabled={this.state.loading}
                    error={this.state.inputError && this.state.username === ''}
                />
                <WhiteSpace size="lg" />
                <InputItem 
                    placeholder="Contraseña" 
                    name="password" 
                    type={this.state.inputType}
                    extra={this.state.inputType === 'input' ? <ShowIcon style={{width: '22px', height: '22px'}} /> : <HideIcon style={{width: '22px', height: '22px'}}/>} 
                    onExtraClick={this.toggleViewPassword}
                    value={this.state.password} 
                    onChange={e => this.setState({password: e})} 
                    disabled={this.state.loading}
                    error={this.state.inputError && this.state.password === ''}
                />
                <WhiteSpace size="lg" />
                <Button 
                    icon="check-circle-o" 
                    loading={this.state.loading}
                    type="primary" 
                    onClick={this.handleSubmit}
                    disabled={this.state.loading}    
                >Ingresar</Button>
                <WhiteSpace size="lg" />
                <div style={{textAlign: 'center', color: 'red', display: this.state.errorMsg}} >Usuario o contraseña incorrectos.</div>
            </WingBlank>
        );
    }
}

const mapDispatchToProps = {
    userLoggedIn
}

export default connect(null, mapDispatchToProps)(withRouter(Login));