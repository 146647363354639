import { REQUEST_ITEM_DETAILS, REICIVE_ITEM_DETAILS } from '../actions/itemDetails';

const defaultState = { data: {} };

export const itemDetailsReducer = (state = defaultState, action) => {
    switch(action.type) {
        case REQUEST_ITEM_DETAILS:
            return { 
                state: {...state},
                isFetching: true 
            };
        case REICIVE_ITEM_DETAILS:
            const index = action.idItem;
            const data = action.payload;
            return { 
                data: {...state, [index]: data },
                isFetching: false 
            };
        default:
            return state;
    }
}