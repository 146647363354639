import axios from 'axios';

export const INDEX_PAGE = 'INDEX_PAGE';
export const indexPage = (page) => {
    return {
        type: INDEX_PAGE,
        payload: page
    }
}

export const REQUEST_GROUPS = 'REQUEST_GROUPS';
export const requestGroups = (data) => {
    return {
        type: REQUEST_GROUPS,
        payload: {
            data
        }
    }
}

export const REICIVE_GROUPS = 'REICIVE_GROUPS';
export const reiciveGroups = (data) => {
    return {
        type: REICIVE_GROUPS,
        payload: data,
        reicivedAt: Date.now()
    }
}

export const getGroups = () => {
    return async (dispatch) => {
        dispatch(requestGroups());
        try {
            const result = await axios.get('groups');
            const data = result.data;
            return dispatch(reiciveGroups(data));
        }
        catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
        
    }
}

function shouldFetchGroups(state) {
    const groups = state.groupsReducer.data;
    if(groups.length > 0) {
        return false;
    } else {
        return true;
    }
}

export const getGroupsIfNeeded = () => {
    return(dispatch, getState) => {
        if(
            shouldFetchGroups( getState() )
        ) {
            return(
                dispatch( getGroups() )
            );
        } else {
            return Promise.resolve();
        }
    }
}