import React from 'react';
import { connect } from 'react-redux';
import { WingBlank, WhiteSpace, Card } from 'antd-mobile';

const Profile = props => {
    const avatarUrl = process.env.REACT_APP_BASE_URL + props.user.avatarUrl;
    return (
        <WingBlank>
            <Card>
                <WhiteSpace/>
                <div style={{ textAlign: 'center' }}>
                    <img src={avatarUrl} style={{ width: '80px'}} alt="Logo" />
                </div>
            </Card>
        </WingBlank>
    )
}

const mapStateToProps = state => {
    return {
        user: state.userReducer,
    }
}

export default connect(mapStateToProps)(Profile);