import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, WingBlank, WhiteSpace } from 'antd-mobile';

class Dashboard extends Component {
    
    constructor(props) {
        super(props);
        this.state = {};
        this.props.setTitle('Dashboard');
    }
    
    render() {
        return (
            <div>
                <WhiteSpace />
                <WingBlank>
                <Button 
                    onClick={() => this.props.history.push('/grupos')}>
                    Lista de Artículos
                </Button>
            </WingBlank>
            </div>
        );    
    }
}

export default withRouter(Dashboard);