import { REQUEST_ITEMS, REICIVE_ITEMS } from '../actions/items';

const defaultState = { data: {} };

export const itemsReducer = (state = defaultState, action) => {
    switch(action.type) {
        case REQUEST_ITEMS:
            return { 
                state: {...state},
                isFetching: true 
            };
        case REICIVE_ITEMS:
            return { 
                data: {...state.state.data, [action.idSubgroup]: action.payload},
                isFetching: false 
            };
        default:
            return state;
    }
}