import React, { Component } from 'react';
import GroupsList from './groupsList';

export default class BrowseGroups extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.props.setTitle('GRUPOS');
    }

    render() {
        return (
            <GroupsList {...this.props}/>
        )
    }
}