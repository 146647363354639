import thunkMiddleware from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { groupsReducer, indexReducer } from './reducers/groups';
import { subgroupsReducer } from './reducers/subgroups';
import { itemsReducer } from './reducers/items';
import { itemDetailsReducer } from './reducers/itemDetails';
import { userReducer } from './reducers/user';
import { saveState, loadState } from './localState';

const persistedState = loadState();

const reducers = combineReducers({
    groupsReducer,
    indexReducer,
    subgroupsReducer,
    itemsReducer,
    itemDetailsReducer,
    userReducer,
});

const store = createStore(reducers, persistedState, applyMiddleware(thunkMiddleware));

store.subscribe(() => {
    saveState({
        userReducer: store.getState().userReducer
    });
} );

export default store;